
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Item } from '../store/models/ItemModel';
import { Orderline } from '../store/models/OrderlineModel';
import { StringHelper } from '@/helpers/StringHelper';
import { ColorHelpers } from '@/helpers/ColorHelper';
import { InvoiceService } from '@/services/invoice-service';
import J1lImg from '@/components/J1lImg.vue';
import CustomerPaymentDialog from '@/components/CustomerPaymentDialog.vue';
import PayInvoiceDialog from '@/components/PayInvoiceDialog.vue';
import { Invoice } from '@/store/models/InvoiceModel';

@Component({
  components: { J1lImg, CustomerPaymentDialog, PayInvoiceDialog }
})
export default class extends Vue {
  $refs!: {
    J1lImg: HTMLFormElement;
    CustomerPaymentDialog: HTMLFormElement;
    PayInvoiceDialog: HTMLFormElement;
  };
  /* Properties */

  /* Store Actions */
  @Prop()
  orderlineGroup!: any;
  @Prop()
  invoiceId!: string;
  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: boolean) {
    if (!val) {
      this.closeDialog();
    }
  }

  /* Data */
  dialog: boolean = false;
  paymentDialog: boolean = false;
  paymentProfileId: string = '';
  orderlines: Orderline[] = [];
  isTextInputValid: boolean = false;
  selectedInvoice: Invoice = new Invoice();
  invoices: any = [];
  invoicesInfo: any = [
    {
      header: '',
      subheader: '',
      description: ''
    }
  ];
  isDownloadingInvoice: boolean = false;
  /* Methods */
  async openDialog() {
    this.dialog = true;
    this.getInvoices();
  }
  async getInvoices() {
    if (this.invoiceId) {
      const response = await InvoiceService.GetInvoiceById(this.invoiceId);
      this.invoices = [];
      this.invoices.push(response.data);
      this.invoicesInfo = StringHelper.GetOrderStatusForInvoicing(this.invoices);
    } else if (this.orderlineGroup) {
      this.orderlines = this.orderlineGroup.Orderlines;

      const response = await InvoiceService.GetInvoiceByPO(
        this.orderlineGroup.GroupByValue
      );
      this.invoices = response.data;
      this.invoicesInfo = StringHelper.GetOrderStatusForInvoicing(this.invoices);
    }
  }
  async downloadInvoicePdf(invoice: any) {
    this.isDownloadingInvoice = true;
    await InvoiceService.GenerateInvoice(invoice.Id);
    this.isDownloadingInvoice = false;
  }

  async downloadInvoice(invoice: any) {
    this.isDownloadingInvoice = true;
    await InvoiceService.DownloadInvoice(invoice.Id, invoice.InvoiceNumber);
    this.isDownloadingInvoice = false;
  }
  trackPackage(invoice: any) {
    window.open(
      'https://www.ups.com/track?loc=en_US&tracknum=' +
        invoice.PackingList.TrackingNumber +
        '&requester=WT/trackdetails'
    );
  }
  goToItem(item: Item) {
    let route = this.$router.resolve({
      name: 'ItemInformation',
      params: { id: item.Id }
    });
    window.open(route.href, '_blank');
  }
  goToOrderDetail() {
    this.$router.push({
      name: 'OrderView',
      params: { po: this.orderlineGroup.GroupByValue }
    });
  }
  openPayInvoiceDialog(invoice: Invoice) {
    this.selectedInvoice = invoice;
    this.$refs.PayInvoiceDialog.openDialog();
  }

  closeDialog() {
    this.dialog = false;
  }
  determineStatusColor(invoice: any) {
    if (invoice) {
      let fontcolor = ColorHelpers.getInvoiceStatus(invoice.Status);
      return fontcolor;
    }
  }

  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
}
