
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import J1lImg from '@/components/J1lImg.vue';
import TrackingInfoDialog from '@/components/TrackingInfoDialog.vue';
import { Orderline, OrderlineDetails } from '@/store/models/OrderlineModel';
import J1LDateIcon from '@/components/J1LDateIcon.vue';
import RushIcon from '@/components/Icons/RushIcon.vue';
import { Address } from '@/store/models/AddressModel';
import { StringHelper } from '@/helpers/StringHelper';
import { Item } from '@/store/models/ItemModel';
import { DateHelper } from '@/helpers/DateHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';

@Component({
  components: {
    J1lImg,
    J1LDateIcon,
    RushIcon,
    TrackingInfoDialog
  }
})
export default class extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
    SearchBox: HTMLFormElement;
    J1lImg: HTMLFormElement;
    TrackingInfoDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) orderline!: Orderline;
  @Prop({ required: true, default: '' })
  itemId!: string;
  @Prop({ required: true })
  backgroundColor!: string;
  /* Store Actions */
  /* Watchers */
  /* Data */
  isGrabbingHiRezImage: boolean = false;
  showTooltip: boolean = false;
  colors: string[] = ['rgb(102,102,102)', 'blue', 'magenta', 'yellow', 'white', 'white'];
  colorIcons: string[] = [
    'looks_one',
    'looks_two',
    'looks_3',
    'looks_4',
    'looks_5',
    'looks_6'
  ];
  /* Utility Functions */
  isMobile() {
    return window.innerWidth <= 768;
  }
  GetShipVerbiage() {
    return DateHelper.GetShipVerbiage(this.orderline);
  }
  getRollDirectionSvg() {
    switch (this.orderline.RollDirection.Id) {
      case 3:
        return '3unwind.svg';
      case 4:
        return '4unwind.svg';
      default:
        return '4unwind.svg';
    }
  }
  grabbingHighRezImgChange(isLoading: boolean) {
    this.isGrabbingHiRezImage = isLoading;
  }
  openTrackingInfoDialog() {
    this.$refs.TrackingInfoDialog.showDialog();
  }
  getTextColor(status: string) {
    return StringHelper.getStatusTextColorClass(status);
  }
  getBackgroundColorByStatus(status: string) {
    return StringHelper.getStatusTextBackgroundColorClass(status);
  }
  getBorderColorByStatus(status: string) {
    return StringHelper.getStatusTextBorderColorClass(status);
  }
  goToItem(item: Item) {
    let route = this.$router.resolve({
      name: 'ItemInformation',
      params: { id: item.Id }
    });
    window.open(route.href, '_blank');
  }
  /* Events */
  handleItemActionEvent(index: number) {
    switch (index) {
      case 0:
        this.$router.push({
          name: 'ManageItems',
          params: {
            partnumber: this.orderline.Item.PartNumber
          }
        });
        break;
      case 1:
        this.$emit('orderSearchByPartNumber', this.orderline.Item.PartNumber);
        break;
    }
  }
  /* Loaders */
  /* Computed */
  get determineTextColor() {
    switch (this.orderline.Status) {
      case 'CLOSED':
        return 'j1l-black';
      case 'HOLD':
        return 'bubble-gum-purple';
      case 'PRINTING SOON (EDITABLE)':
        return 'mintGreen';
      case 'SENT TO PRESS':
        return 'mustard';
      case 'PRINTED WAITING TO CUT':
        return 'hotPink';
      case 'CUT WAITING TO REWIND':
      case 'PREPARING TO PICKUP':
      case 'READY TO PICKUP':
      case 'PREPARING TO SHIP':
      case 'SHIPPED':
      case 'READY TO SHIP':
        return 'j1lorange';
      default:
        return 'bold-blue';
    }
  }
  get cardHeight() {
    return 125;
  }
  get price() {
    let price =
      this.orderline.PriceEach * this.orderline.Quantity + this.orderline.RushFee;
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }
  showHighRez() {
    this.$refs.J1lImg.showHighRezDialog();
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
